import React from 'react'

import Layout from '../components/layout'
import PageHeader from '../components/organisms/PageHeader'

function NotFoundPage() {
  return (
    <Layout>
      <PageHeader
        headline={'Page not found'}
        blurb={`
          <p>Looks like this page might be under construction?</p>
          <p>
            Try selecting another page from the menu, or click our logo above
            to head back to the home page.
          </p>
        `}
      />
    </Layout>
  )
}

export default NotFoundPage
