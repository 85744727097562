import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { TEXT_SHADOW, MAX_CONTENT_WIDTH_SML } from '../../constants/global.js'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        minHeight: '60vh',
        textAlign: 'center',
        textShadow: TEXT_SHADOW,
        overflow: 'hidden',
    },
    bannerContent: {
        maxWidth: MAX_CONTENT_WIDTH_SML,
        padding: `${theme.spacing(25)}px ${theme.spacing(3)}px ${theme.spacing(
            15
        )}px ${theme.spacing(3)}px`,
        zIndex: 2,
    },
    bannerTitle: {
        textTransform: 'uppercase',
        fontSize: theme.typography.pxToRem(38),
        fontWeight: theme.typography.fontWeightLight,
        marginTop: 0,
        marginBottom: '32px',

        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(52),
        },

        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(68),
        },
    },
}))

function PageHeader({ headline, blurb, children }) {
    const classes = useStyles()

    return (
        <header className={classes.root}>
            <div className={classes.bannerContent}>
                <Typography
                    className={classes.bannerTitle}
                    variant="h1"
                    dangerouslySetInnerHTML={{ __html: headline }}
                />
                <Typography
                    variant="body1"
                    component={'div'}
                    dangerouslySetInnerHTML={{ __html: blurb }}
                />
                {children}
            </div>
        </header>
    )
}

PageHeader.propTypes = {
    headline: PropTypes.string.isRequired,
    blurb: PropTypes.string.isRequired,
    children: PropTypes.node,
}

export default PageHeader
